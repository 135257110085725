<template>
  <div>
    <c-tab
      class="q-mt-sm preStartup-tab-no-padding"
      type="tabcard"
      align="left"
      :height="tabHeight"
      :editable="editInfo"
      :tabItems.sync="preStartupCheck.equipments"
      :inlineLabel="true"
      :addTab="editInfo && !disabled"
      :removeTab="editInfo && !disabled"
      removeConditionCol="checkCompleteFlag"
      removeConditionVal="Y"
      v-model="equipTab"
      @addTab="addTab"
      @removeTab="removeTab"
      @tabClick="tabClick"
    >
      <template v-slot:default="tab">
        <component
          :is="tab.component"
          :key="tab.key"
          :param="param"
          :preStartupCheck.sync="preStartupCheck"
          :equipmentCd="tab.equipmentCd"
          :research.sync="research"
        />
      </template>
    </c-tab>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import { uid } from 'quasar'
export default {
  name: 'pre-startup-review-check-list',
  props: {
    param: {
      type: Object,
      default: () => ({
        sopPrestartupCheckId: '',
        psrCheckStatusCd: '',
        isSearch: false,
      }),
    },
    preStartupCheck: {
      type: Object,
      default: () => ({
        sopPrestartupCheckId: '',  // 가동전점검 일련 번호
        plantCd: null,  // 사업장코드
        checkName: '',  // 점검명
        checkPurpose: '',  // 점검 목적
        psrEquipmentStatusCd: null,  // 설비 상태
        checkDeptCd: null,  // 점검부서
        sopMocId: '',  // MOC 일련번호
        checkCancarrierId: '',  // 점검 책임자
        checkResultTotal: '',  // 점검결과 종합
        psrCheckStatusCd: '',  // 진행 단계
        regUserId: '',  // 등록자 ID
        chgUserId: '',  // 수정자 ID
        equipments: [],
        deleteEquipments: [],
      }),
    },
    equipTab: {
      type: String,
      default: '',
    },
    editInfo: {
      type: Boolean,
      default: false,
    },
    research: {
      type: Object,
      default: () => ({
        impr: '',
        item: '',
      }),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    contentHeight: null,
  },
  data() {
    return {
      editable: true,
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '90%',
        param: {},
        closeCallback: null,
      },
    };
  },
  computed: {
    tabHeight() {
      return String(this.contentHeight - 150);
    },
  },
  watch: {
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
    this.init();
  },
  mounted() {
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      this.equipListUrl = selectConfig.sop.psr.check.equips.url;
      // url setting
      // code setting
      // list setting
    },
    getEquips() {
      this.$http.url = this.equipListUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        sopPrestartupCheckId: this.param.sopPrestartupCheckId,
      };
      this.$http.request((_result) => {
        this.equipTab = _result.data[0].equipmentCd;
        this.$_.forEach(_result.data, item => {
          item.name = item.equipmentCd;
          item.label = item.equipmentName;
          item.icon = 'precision_manufacturing'
          item.component = () => import(`${'./preStartupFacility.vue'}`)
          item.key = uid()
        })
        this.$set(this.preStartupCheck, 'equipments', _result.data)
      },);
    },
    addTab() {
      this.popupOptions.title = 'LBL0000521'; // 설비 검색
      this.popupOptions.param = {
        type: 'multiple'
      };
      this.popupOptions.target = () => import(`${'@/pages/common/equip/equipPop.vue'}`);
      this.popupOptions.width = '50%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeFacilityPopup;
    },
    closeFacilityPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        data = this.$_.orderBy(data, 'equipmentCd', 'asc')
        if (!this.preStartupCheck.equipments || this.preStartupCheck.equipments.length === 0) {
          this.equipTab = data[0].equipmentCd;
        }
        this.$_.forEach(data, item => {
          if (this.$_.findIndex(this.preStartupCheck.equipments, { name: item.equipmentCd }) === -1) {
            this.preStartupCheck.equipments.push({
              name: item.equipmentCd,
              label: item.equipmentName,
              icon: 'precision_manufacturing',
              component: () => import(`${'./preStartupFacility.vue'}`),
              sopPrestartupCheckId: '',  // 가동전점검 일련 번호
              equipmentCd: item.equipmentCd,  // 설비코드
              equipmentName: item.equipmentName,  // 설비명
              equipmentTypeCd: item.equipmentTypeCd, // 설비 분류(상하위구조)
              equipmentTypeName: item.equipmentTypeName, // 설비 분류명(상하위구조)
              checkUserId: '',  // 점검 책임자
              checkScheduleDate: '',  // 점검 예정일
              checkDate: '',  // 점검일
              checkResultTotal: '',  // 점검 결과 종합
              checkCompleteFlag: 'N',  // 점검 완료 여부
              regUserId: '',  // 등록자 ID
              chgUserId: '',  // 수정자 ID
              checkTypes: [], // 점검유형
              checkItems: [], // 점검항목
              editFlag: 'C',
            })
          }
        })
      }
    },
    removeTab(tab) {
      window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM',
        // 삭제하실 경우 해당 공정에 해당하는\n\r점검정보 및 결과목록도 변경됩니다.\n\r진행하시겠습니까?
        message: 'MSG0000600',
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          if (!this.preStartupCheck.deleteEquipments) {
            this.preStartupCheck.deleteEquipments = [];
          }
          if (this.$_.findIndex(this.preStartupCheck.deleteEquipments, tab) === -1 && tab.editFlag !== 'C') {
            this.preStartupCheck.deleteEquipments.push(tab)
          }
          
          this.$http.url = this.deleteEquipUrl;
          this.$http.type = 'DELETE';
          this.$http.param = {
            data: Object.values(this.preStartupCheck.deleteEquipments)
          };
          this.$http.request(() => {
            // this.$set(this.preStartupCheck.equipments, this.$_.findIndex(this.preStartupCheck.equipments, tab), null)
            this.preStartupCheck.equipments.splice(this.$_.findIndex(this.preStartupCheck.equipments, tab), 1)
            if (this.preStartupCheck.equipments && this.preStartupCheck.equipments.length > 0) {
              this.equipTab = this.preStartupCheck.equipments[0].name
            }
            this.preStartupCheck.deleteEquipments = [];
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    tabClick(tab) {
      this.equipTab = tab.name;
    },
  }
};
</script>
