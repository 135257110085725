var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("c-tab", {
        staticClass: "q-mt-sm preStartup-tab-no-padding",
        attrs: {
          type: "tabcard",
          align: "left",
          height: _vm.tabHeight,
          editable: _vm.editInfo,
          tabItems: _vm.preStartupCheck.equipments,
          inlineLabel: true,
          addTab: _vm.editInfo && !_vm.disabled,
          removeTab: _vm.editInfo && !_vm.disabled,
          removeConditionCol: "checkCompleteFlag",
          removeConditionVal: "Y",
        },
        on: {
          "update:tabItems": function ($event) {
            return _vm.$set(_vm.preStartupCheck, "equipments", $event)
          },
          "update:tab-items": function ($event) {
            return _vm.$set(_vm.preStartupCheck, "equipments", $event)
          },
          addTab: _vm.addTab,
          removeTab: _vm.removeTab,
          tabClick: _vm.tabClick,
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (tab) {
              return [
                _c(tab.component, {
                  key: tab.key,
                  tag: "component",
                  attrs: {
                    param: _vm.param,
                    preStartupCheck: _vm.preStartupCheck,
                    equipmentCd: tab.equipmentCd,
                    research: _vm.research,
                  },
                  on: {
                    "update:preStartupCheck": function ($event) {
                      _vm.preStartupCheck = $event
                    },
                    "update:pre-startup-check": function ($event) {
                      _vm.preStartupCheck = $event
                    },
                    "update:research": function ($event) {
                      _vm.research = $event
                    },
                  },
                }),
              ]
            },
          },
        ]),
        model: {
          value: _vm.equipTab,
          callback: function ($$v) {
            _vm.equipTab = $$v
          },
          expression: "equipTab",
        },
      }),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }